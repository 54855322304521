const views = {
  NONE: 0,
  HOME_VIEW: 1,
  SERVICES_VIEW: 2,
  ABOUT_VIEW: 3,
  BOOKING_VIEW: 4,
  PROFILE_VIEW: 5,
};

export const navigationBarDisplayView = [
  views.ABOUT_VIEW,
  views.BOOKING_VIEW,
  views.PROFILE_VIEW,
];

export const tabBarDisplayView = [
  views.SERVICES_VIEW,
  views.ABOUT_VIEW,
  views.BOOKING_VIEW,
  views.PROFILE_VIEW,
];

export default views;
