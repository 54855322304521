<template>
  <div class="editor-tool">
    <v-navigation-drawer permanent touchless width="100%">
      <template #prepend>
        <v-row class="my-4 ma-6" no-gutters>
          <v-col cols="12" class="d-flex justify-space-between pr-2">
            <v-btn
              color="editor_undo_redo"
              class="mr-1"
              text
              icon
              @click="backToDrafts"
            >
              <v-icon>$arrow-left</v-icon>
            </v-btn>
            <div class="undo-redo-actions">
              <v-btn
                color="editor_undo_redo"
                text
                icon
                :disabled="canUndoHandle"
                @click="undoHandle"
              >
                <v-icon>$undo</v-icon>
              </v-btn>
              <v-btn
                color="editor_undo_redo"
                text
                icon
                :disabled="canRedoHandle"
                @click="redoHandle"
              >
                <v-icon>$redo</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
      <v-expansion-panels v-model="panel" class="editor-tool__panels">
        <template>
          <calendesk-information-message
            :html="
              $trans('mobile_account_editor_info_message', {
                google: googlePlayUrl,
                apple: appStoreUrl,
              })
            "
          />
          <calendesk-information-message>
            {{
              $trans("mobile_account_editor_info_message_2", {
                company: $config("company_name"),
              })
            }}
          </calendesk-information-message>
          <v-expansion-panel v-for="(tool, index) in mobileTools" :key="index">
            <v-expansion-panel-header @click="setPreviewView(tool.type)">
              <div class="editor-tool__panels--header">
                <div class="text-subtitle-1">
                  {{ tool.title }}
                </div>
                <div v-if="tool.subtitle" class="text-body-2">
                  {{ tool.subtitle }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <component :is="tool.component" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
      <template #append>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center my-4">
            <v-btn
              color="dark"
              outlined
              depressed
              :loading="loadingSave"
              :disabled="loadingSave || loadingPublish"
              class="ma-2"
              @click="saveSettings"
            >
              {{ $trans("tool_save_draft") }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loadingPublish"
              :disabled="loadingPublish || loadingSave"
              class="ma-2"
              @click="publishDraft"
            >
              {{ $trans("publish") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import mobileViews from "@/views/dashboard/pages/Tools/Mobile/components/mobile-preview/mobileViews";
import dialogTypes from "../../Dialogs/dialogTypes";
import dialogSize from "../../../lib/calendesk-js-library/components/dialogs/dialogSize";
import api from "@/lib/calendesk-js-library/api/api";
import planActions from "@/calendesk/mixins/planActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "EditorTool",
  components: {
    MobileGlobalForm: () => import("../forms/mobile/MobileGlobalForm"),
    CalendeskInformationMessage: () =>
      import(
        "@/lib/calendesk-js-library/components/CalendeskInformationMessage"
      ),
  },
  mixins: [planActions],
  data() {
    return {
      panel: 0,
      loadingSave: false,
      loadingPublish: false,
      mobileTools: [
        {
          type: mobileViews.NONE,
          title: this.$trans("editor_tool_global_settings_title"),
          subtitle: this.$trans("editor_tool_global_settings_subtitle"),
          component: () => import("../forms/mobile/MobileGlobalForm"),
        },
        {
          type: mobileViews.HOME_VIEW,
          title: this.$trans("mobile_home_form_title"),
          component: () => import("../forms/mobile/MobileHomeForm"),
        },
        {
          type: mobileViews.SERVICES_VIEW,
          title: this.$trans("mobile_services_form_title"),
          component: () => import("../forms/mobile/MobileServicesForm"),
        },
        {
          type: mobileViews.ABOUT_VIEW,
          title: this.$trans("mobile_about_us_form_title"),
          component: () => import("../forms/mobile/MobileAboutUsForm"),
        },
        {
          type: mobileViews.BOOKING_VIEW,
          title: this.$trans("mobile_bookings_form_title"),
          component: () => import("../forms/mobile/MobileBookingsForm"),
        },
        {
          type: mobileViews.PROFILE_VIEW,
          title: this.$trans("mobile_account_form_title"),
          component: () => import("../forms/mobile/MobileAccountForm"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      canUndoMobile: "mobileEditor/canUndo",
      canRedoMobile: "mobileEditor/canRedo",
    }),
    canUndoHandle() {
      return this.canUndoMobile;
    },
    canRedoHandle() {
      return this.canRedoMobile;
    },
    googlePlayUrl() {
      return '<a href="https://google-c.calendesk.com" target="_blank">Google Play</a>';
    },
    appStoreUrl() {
      return '<a href="https://apple-c.calendesk.com" target="_blank">Apple Store</a>';
    },
  },
  methods: {
    ...mapMutations({
      updateConfiguration: "setup/UPDATE_CONFIGURATION",
      setPreviewView: "mobileEditor/SET_PREVIEW_VIEW",
    }),
    ...mapActions({
      undoChanges: "editor/undoChanges",
      undoChangesMobile: "mobileEditor/undoChanges",
      redoChangesMobile: "mobileEditor/redoChanges",
      redoChanges: "editor/redoChanges",
      saveConfiguration: "editor/saveConfiguration",
      fetchSettings: "setup/fetchSettings",
      saveConfigurationMobile: "mobileEditor/saveConfiguration",
    }),
    backToDrafts() {
      this.$router.push({ name: "tools", params: { tab: "app" } });
    },
    undoHandle() {
      this.undoChangesMobile();
    },
    redoHandle() {
      this.redoChangesMobile();
    },
    saveSettings() {
      pushEvent("saveMobileDraft");

      this.loadingSave = true;
      this.saveConfigurationMobile()
        .then(() => {
          this.$router.push({ name: "tools", params: { tab: "app" } });
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    publishDraft() {
      pushEvent("publishMobileDraft");

      if (this.canCreateMobileAppForCustomers) {
        this.loadingPublish = true;
        api
          .publishMobileDraft(this.$route.params.id)
          .then(() => {
            this.fetchSettings().then(() => {
              this.openDialog({
                type: dialogTypes.PUBLISH_MOBILE_DRAFT_DIALOG,
                size: dialogSize.LARGE,
                title: this.$trans("publish_mobile_draft_dialog_title"),
              }).finally(() => {
                this.loadingPublish = false;
              });
            });
          })
          .catch((error) => this.$log.error(error));
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-tool {
  height: 100%;
  background: var(--v-editor_tool_background-base);
  color: var(--v-editor_tool_color-base);
}

.editor-tool__panels--header .text-subtitle-1 {
  color: var(--v-dark-base);
}

.editor-tool__panels--header .text-body-2 {
  color: var(--v-dark-base);
  opacity: 0.54;
}
</style>
